import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import apple from "../../Assets/Projects/apple.png";
import kata_chat from "../../Assets/Projects/kata-chat.png";
import task_manager from "../../Assets/Projects/task-manager.png";
import mycontrast from "../../Assets/Projects/mycontrast.png";
import daalao from "../../Assets/Projects/daalao.png";
import stardriver from "../../Assets/Projects/stardriver.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Personal Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kata_chat}
              isBlog={false}
              title="Kata Chat"
              description="A personal chat room or workspace built with React.js, Material-UI, and Firebase, designed for sharing resources and hanging out with friends. It features real-time messaging, image sharing, and reactions to messages for an engaging and interactive experience."
              demoLink="https://chat.chaanoun-younes.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={task_manager}
              isBlog={false}
              title="Task Manager"
              description="This Task Management System is built with React.js, TailwindCSS, and Vite on the front end, and Node.js, Express, and MongoDB on the back end. It features a user-friendly dashboard for tracking tasks, real-time updates using Redux Toolkit, intuitive form handling with React Hook Form, and data visualization with Recharts. Key functionalities include task prioritization, team collaboration, and a responsive design for seamless usability."
              demoLink="https://task-manager.chaanoun-younes.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={apple}
              isBlog={false}
              title="Apple"
              description="This project is a sleek replica of Apple's website, built with React.js, Three.js, GSAP, and TailwindCSS. It features interactive animations, 3D elements, and optimized performance using Vite with plugins for asset compression and error monitoring via Sentry. Responsive and visually engaging, it delivers a modern, high-performance user experience."
              demoLink="https://apple.chaanoun-younes.com/"              
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <h1 className="project-heading">
        Professional <strong className="purple">Project</strong>
        </h1>
        <p style={{ color: "white" }}>
        Here are some projects I have managed and contributed to.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Event Management Application"
              description="A comprehensive solution for event planning, featuring tools for managing event layouts, seating arrangements, and participant data. Built with React.js and Material-UI on the frontend and Express.js with MongoDB on the backend, the application offers an intuitive drag-and-drop interface for designing layouts with advanced features like zoom, pan, multi-select, and rotation. Key functionalities include user authentication, event creation, participant management, and the ability to export seating arrangements in various formats, making it a powerful and user-friendly tool for event organizers."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Multi-Store Management App"
              description="A powerful application designed to help clients manage multiple WooCommerce stores from a single platform. It allows store owners to view and manage orders, track store performance, and access key statistics through an intuitive dashboard. Built with React.js and Horizon UI for a sleek, responsive frontend, and powered by Node.js, Express, MongoDB, and Socket.IO on the backend, the app ensures real-time updates, efficient management, and an exceptional user experience."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              
              isBlog={false}
              title="Football Match Management App"
              description="A dedicated application for football match referees to efficiently manage and track match events. The app allows referees to view their assigned matches, update match information in real time, and record key events like goals, fouls, substitutions, and player statistics. A robust dashboard provides comprehensive match summaries and statistics for better decision-making and reporting.

Developed with React.js and Horizon UI for an intuitive and responsive user interface, and powered by Node.js, Express, MongoDB, and Socket.IO for real-time updates and secure data management, the app ensures a seamless experience."
                         
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">WordPress Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on using Wordpress.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={daalao}
              isBlog={false}
              title="Fonds Daalao"
              description="Fonds DAALAO, est une organisation non gouvernementale qui opère auprès des associations locales africaines comme une banque financière et humaine, d’aide au développement."
              demoLink="https://fondsdaalao.org"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={stardriver}
              isBlog={false}
              title="Star Driver"
              description="Star Driver sets the standard for luxury chauffeur service in Brussels, serving an elite clientele with unrivaled professionalism and care, providing the highest quality chauffeur-driven experiences."
              demoLink="https://stardriver.be"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mycontrast}
              isBlog={false}
              title="My Contrast"
              description="A professional and responsive blog created using WordPress to showcase Yahya's research and insights. The blog features a clean, modern design with easy navigation, allowing visitors to explore articles seamlessly. "
              demoLink="https://my-contrast.com"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
